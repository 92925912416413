<template>
  <div class="bigop">
    <div class="bigop_lis">
      <div class="win_information" v-for="(item,index) in newbox" :key="index">
        <div class="win_information_data" :style="{backgroundImage:'url('+item.back_logo+')'}">
          <img :src=item.logo alt="">
        </div>
        <div class="win_information_user" >
          <div class="win_information_user_img" :style="{backgroundImage:'url('+item.p_back_logo+')'}">
            <img :src=item.uLogo alt="">
            <h2>{{item.username}}</h2>
          </div>
        </div>

        <p>{{item.title}}</p>
        <div id="bottomdiv" :style="{backgroundImage:'url('+item.back_logo+')'}"></div>
      </div>
    </div>
    <!-- <button @click="big()">Big</button>
    <button @click="small()">Big</button> -->
  </div>
</template>

<script>
export default {
  props:['newbox','newboxposition','scrollPosition'],
  data(){
    return {
      
    }
  },
  methods:{
    //中奖轮播图首次加载执行
    big() {
      document.getElementsByClassName("bigop_lis")[0].style.transition = "left 2s";
      document.getElementsByClassName("bigop_lis")[0].style.left = "0px";
    },
    //中奖轮播图n次加载执行
    small() {
      document.getElementsByClassName("bigop_lis")[0].style.transition = "left 0s";
      document.getElementsByClassName("bigop_lis")[0].style.left = -(this.scrollPosition * this.newboxposition) + 'px'; 
      setTimeout( () => {
          document.getElementsByClassName("bigop_lis")[0].style.transition = "left 2s";
          document.getElementsByClassName("bigop_lis")[0].style.left = "0px";
      },100)
    }
  },
  mounted(){
    this.small()
  }
}
</script>


<style scoped lang="less">
.bigop{
  width: 100vw;
}
.bigop_lis{
  width: 100vw;
  display: flex;
  position: relative;
}
.win_information{
// width: 50%;
width: 9.5rem;
height: 15%;
margin: 0 5px;
// overflow:hidden;
background-color: #1C1D32;
border-radius: 5px 5px 0 0 ;
text-align: center;
p{
  width: 100%;
  height: 45px;
  word-wrap:break-word;
  overflow:hidden;
  color: #67706a;
  font-weight: 700;
}
}
.win_information_data{
width: 100%;
height: 80%;
border-radius: 5px 5px 0 0 ;
background-size: 100% 100%;
img{
  width: 100%;
  height: 100px;
}
}
#bottomdiv{
background-color: #666;
height: 5px;
width: 9.5rem;
margin-top: 10px;
background-size: 100% 100%;
}

.win_information_user{
width: 100%;
height: 80%;
border-radius: 5px 5px 0 0 ;
display: none;
.win_information_user_img{
  width: 100%;
  height: 100px;
  background-repeat:no-repeat;
  background-size: 110% 110%;
  background-position: center;
  transition: all .5s;
  img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-top: 10px;
  }
  h2{
    margin-top: 5px;
    // color: #ff9838;
  }
}
}
.win_information:hover{
  .win_information_data{
    display: none;
  }
  .win_information_user{
    display: block;
  }
}
.win_information_user_img:hover{
  transform: scale(1.1);
}
@media screen and (max-width: 800px) {
    .win_information{
      width: 20.5vw;
    }
    .win_information_user_img{
      height: 70px !important;
      img{
        width: 35px !important;
        height: 35px !important;
      }
      h2{
        font-size: 3vw;
      }
    }
    .win_information_data img{
      height: 70px !important;
    }
    #bottomdiv{
      width: 20.5vw;
    }
    .bigop{
      overflow-y: scroll !important;
    }
    .bigop::-webkit-scrollbar {
      display:none
    }
}
</style>